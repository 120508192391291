<div class="modal-header product">
  <p class="text-light-black product header-title title m-0"> {{product.name}} </p>
  <button type="button" class="close close-modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">
      <svg height="24" width="24" viewBox="0 0 24 24">
        <path
          d="M12.0001 10.2322L5.88398 4.11612L4.11621 5.88389L10.2323 12L4.11621 18.1161L5.88398 19.8839L12.0001 13.7678L18.1162 19.8839L19.884 18.1161L13.7679 12L19.884 5.88389L18.1162 4.11612L12.0001 10.2322Z">
        </path>
      </svg>
    </span>
  </button>
</div>
<div class="modal-body">

  <div class="product-view">


    <div class="restaurent-product-img w-100">

      <div *ngIf="!product?.picture" src="assets/img/Logo-default.jpg" class="img-fluid full-width"></div>
      <div *ngIf="product?.picture.path" [style.backgroundImage]="'url('+ url + product.picture.path +')'"
        class="img-fluid full-width"></div>

    </div>

    <div class="restaurent-product-caption-box w-100"> <span class="text-grey-white">{{product.description}}</span></div>


  </div>


</div>
