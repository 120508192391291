import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from './core/guard/role/role.guard';
import { SigninGuard } from './core/guard/signin/signin.guard';
import { WhiteMarkGuard } from './core/guard/whiteMark/whiteMark.guard';
import { TemplateGuard } from './core/guard/template/Template.guard';
import { LegalsComponent } from './pages/legals/legals.component';
import { Page404Component } from './pages/page404/page404.component';



const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./basic/basic.module').then(m => m.BasicModule),
    canActivate: [WhiteMarkGuard, TemplateGuard],
    data: { allowedTemplate: '' }
  },
  {
    path: 'v1',
    loadChildren: () => import('./template-one/template-one.module').then(m => m.TemplateOneModule),
    canActivate: [TemplateGuard],
    data: { allowedTemplate: '1' }
  },
  {
    path: 'v2',
    loadChildren: () => import('./template-two/template-two.module').then(m => m.TemplateTwoModule),
    canActivate: [TemplateGuard],
    data: { allowedTemplate: '2' }
  },
  {
    path: 'manager',
    loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
    canActivate: [SigninGuard],
    canActivateChild: [RoleGuard],
    data: { allowedRoles: ['MANAGER'] }
  },
  {
    path: 'delivery-man',
    loadChildren: () => import('./delivery-man/delivery-man.module').then(m => m.DeliveryManModule),
    canActivate: [SigninGuard],
    canActivateChild: [RoleGuard],
    data: { allowedRoles: ['DELIVERYMAN'] }
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
    canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: { allowedRoles: ['MANAGER', 'CLIENT'] }
  },
  {
    path: 'restaurants',
    loadChildren: () => import('./restaurants/restaurants.module').then(m => m.RestaurantsModule),
    canActivate: [WhiteMarkGuard],
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: 'not-found',
    component: Page404Component
  },

  {
    path: '**',
    redirectTo: 'not-found'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
