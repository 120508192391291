import { Component, OnInit,Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit {

  @Input() public product;
  @Input() public type;
  url = environment.Api_Url;
  constructor(   
    private modalService: NgbModal,
    ) { }

  ngOnInit(): void {
   
  }


  close() {
    this.modalService.dismissAll();
  }

}
