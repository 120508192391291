import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup;
  message = '';
  type = "login"
  hide = true;
  signIn: NgbModalRef;
  captchaResponse = '';

  captchaKey = environment.recaptchaSiteKey;

  constructor(
    private authServ: AuthService,
    private localStorageServ: LocalStorageService,
    private modalService: NgbModal,
    public translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.email]),
      password: new FormControl('', [Validators.email]),
    });
  }

  signin() {
    this.authServ.signin(this.loginForm.value).then(
      (resp: any ) => {
       
        if (resp.status === true) {
          const user = resp.data;
          this.localStorageServ.setItem('user_email', user.email);
          this.localStorageServ.setItem('user_role', user.roles);
          setTimeout(() => {
            window.location.reload();
          }, 500);

        } else {

          this.message = resp.error?.error.message;
          setTimeout(() => {
            this.message = '';
          }, 5000);
        }
      },
      error => {
        this.message = this.translate.instant('TOASTR.tryAgain');
        setTimeout(() => {
          this.message = '';
        }, 5000);
      }
    );
  }

  close() {
    this.modalService.dismissAll();
  }



  resolved(captchaResponse: string) {
    
    this.captchaResponse = captchaResponse;
  }



}