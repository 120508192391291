<div class="p-0">

    <div *ngIf="message !=='' ">
        <p class="aler alert-danger p-1 small">{{message}} </p>
    </div>

    <div class="register">
        <div class="login-box">
            <form [formGroup]="registerForm" class="form-custom">

                <div class="row">
                    <div class="col-md-12">
                        <h1>
                            {{ 'CLIENT.signup' | translate }} 
                          </h1>
                    </div>
                </div>
                <div class="row">

                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="form-group mb-1">
                            <label class="text-light-grey fs-14">{{ 'CLIENT.lastName' | translate }}</label>
                            <input type="text" name="#" class="form-control form-control-submit"
                                formControlName="lastName" placeholder="Nom" required>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="form-group mb-1">
                            <label class="text-light-grey fs-14">{{ 'CLIENT.firstName' | translate }}</label>
                            <input type="text" name="#" class="form-control form-control-submit"
                                formControlName="firstName" placeholder="Prénom" required>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group mb-1">
                                    <label class="text-light-grey fs-14">{{ 'CLIENT.email' | translate }}</label>
                                    <input type="email" name="#" class="form-control form-control-submit"
                                        formControlName="email" placeholder="Email" required>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-6">
                                <div class="form-group mb-1">
                                    <label class="text-light-grey fs-14">{{ 'CLIENT.phone' | translate }}</label>
                                    <input type="text" name="#" class="form-control form-control-submit"
                                        formControlName="phone" placeholder="Tél" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-6">
                                <div class="form-group mb-1">
                                    <label class="text-light-grey fs-14">{{ 'CLIENT.password' | translate }}</label>
                                        <input [type]="hide ? 'password' : 'text'" id="password-field" name="#" class="form-control form-control-submit"
                                        value="password" placeholder="Password" required formControlName="password">
                                         <div data-name="#password-field" [class.fa-eye-slash]="hide === false" [class.fa-eye]="hide === true" (click)="hide = !hide" class="fa fa-fw field-icon toggle-password"></div>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-6">
                                <div class="form-group mb-1">
                                    <label class="text-light-grey fs-14">{{ 'CLIENT.confirmPassword' | translate }}</label>

                                        <input [type]="hideConf ? 'password' : 'text'" id="password-field" name="#" class="form-control form-control-submit"
                                        value="password" placeholder="Password" required formControlName="confirmPassword">
                                         <div data-name="#password-field" [class.fa-eye-slash]="hideConf === false" [class.fa-eye]="hideConf === true" (click)="hideConf = !hideConf" class="fa fa-fw field-icon toggle-password"></div>
                              
                                 </div>
                            </div>
                        </div>

                        <div class="form-group mb-1 checkbox-reset mt-1">
                            <label class="custom-checkbox mb-0">
                                <input type="checkbox" name="roles" value="DELIVERYMAN" formControlName="roles"
                                    (click)='clicked($event)'> <span class="checkmark">
                                </span> {{ 'CLIENT.signUpDelivMan' | translate }} </label>
                        </div>

                        <div class="d-flex align-items-center justify-content-center mt-1 mb-1 p-2">
                            <re-captcha  class="mt-1 mb-1" (resolved)="resolved($event)" [siteKey]="captchaKey"></re-captcha>
                        </div>
                        <div class="form-group mb-1">
                            <button [disabled]="captchaResponse ===''" type="submit" class="btn-second btn-submit full-width"
                                (click)="createAccount()">{{ 'BUTTONS.createYourAccount' | translate }}</button>
                        </div>
                                <span class="text-light-black fs-12 terms">{{ 'CLIENT.accept1' | translate }}
                                    <a routerLink="/mentions-legales"> {{ 'CLIENT.accept2' | translate }} </a> {{ 'CLIENT.accept3' | translate }} <a routerLink="/mentions-legales">{{ 'CLIENT.accept4' | translate }}</a>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
