<div class="p-0">

  <div *ngIf="message !=='' ">
    <p [class.alert-danger]="!success" [class.alert-success]="success" class="alert p-1 small">
      <span *ngIf="success"> {{ 'CLIENT.'+message | translate }} </span>
      <span *ngIf="!success"> {{ 'CLIENT.'+message | translate }} </span>
    </p>
  </div>

  <div class="register">
    <div class="reset-box">


      <div class="row">
        <div class="col-md-12">
          <h1>
            {{ 'CLIENT.resetpassword' | translate }}
          </h1>
        </div>
      </div>
      <div class="row" *ngIf="proceed === false">
        <div class="col-md-12">
          <form [formGroup]="resetForm" class="form-custom">


            <div class="form-group mb-1">
              <label class="text-light-grey fs-14">{{ 'CLIENT.email' | translate }}</label>
              <input type="email" name="#" class="form-control form-control-submit" formControlName="email"
                placeholder="Email" required>
            </div>


            <div class="d-flex align-items-center justify-content-center mt-1 mb-1 p-2">
              <re-captcha class="mt-1 mb-1" (resolved)="resolved($event)" [siteKey]="captchaKey"></re-captcha>
            </div>
            <div class="form-group mb-1">
              <button [disabled]="captchaResponse ===''" type="submit" class="btn-second btn-submit full-width"
                (click)="SendEmail()">{{ 'BUTTONS.send' | translate }}</button>
            </div>


          </form>
        </div>
      </div>

      <div class="w-100" *ngIf="proceed">
        <div class="col-md-12 p-0">
          <p>{{ 'CLIENT.token' | translate }}</p>
          <form [formGroup]="newPasswordForm" class="form-custom">


            <div class="col-md-12">
              <div class="form-group mb-1">
                <label class="text-light-grey fs-14"> Token </label>
                <input type="text" class="form-control form-control-submit" formControlName="token" placeholder="Token">
              </div>

            </div>



            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-6">
                  <div class="form-group mb-1">
                    <label class="text-light-grey fs-14">{{ 'CLIENT.password' | translate }}</label>
                    <input [type]="hide ? 'password' : 'text'" class="form-control form-control-submit" value="password"
                      placeholder="Password" required formControlName="password">
                    <div data-name="#password-field" [class.fa-eye-slash]="hide === false"
                      [class.fa-eye]="hide === true" (click)="hide = !hide" class="fa fa-fw field-icon toggle-password">
                    </div>

                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-6">
                  <div class="form-group mb-1">
                    <label class="text-light-grey fs-14">{{ 'CLIENT.confirmPassword' | translate }}</label>

                    <input [type]="hideConf ? 'password' : 'text'" class="form-control form-control-submit"
                      value="password" placeholder="Password" required formControlName="confirmPassword">
                    <div data-name="#password-field" [class.fa-eye-slash]="hideConf === false"
                      [class.fa-eye]="hideConf === true" (click)="hideConf = !hideConf"
                      class="fa fa-fw field-icon toggle-password"></div>

                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">

              <div class="d-flex align-items-center justify-content-center mt-1 mb-1 p-2">
                <re-captcha class="mt-1 mb-1" (resolved)="resolved($event)" [siteKey]="captchaKey"></re-captcha>
              </div>
              <div class="row">
                <div class="col-md-12 p-0">
                  <div class="form-group mb-1">
                    <button [disabled]="captchaResponse ===''" type="submit" class="btn-second btn-submit full-width"
                      (click)="resetPassword()">{{ 'BUTTONS.send' | translate }}</button>
                  </div>
                </div>
              </div>


            </div>
          </form>


        </div>
      </div>

    </div>
  </div>
</div>
