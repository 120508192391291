import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FavoritComponent } from './favorit/favorit.component';
import { FooterComponent } from './footer/footer.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProductBoxComponent } from './product-box/product-box.component';
import { ReservationBlocComponent } from './reservation-bloc/reservation-bloc.component';

import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { HttpErrorInterceptor } from '../core/interceptor/http-error.interceptor';
import { RestaurantBoxComponent } from './restaurant-box/restaurant-box.component';
import { FilterComponent } from './filter/filter.component';
import { RestaurantRightsidebarComponent } from './restaurant-rightsidebar/restaurant-rightsidebar.component';
import { AddToCartButtonComponent } from './AddToCardButton/AddToCardButton.component';
import { ProductListComponent } from './ProductsList/ProductsLlist.component';
import { ValidateReservationComponent } from '../shared/modals/validate-reservation/validate-reservation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductCartComponent } from './productsCart/ProductCart.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryManComponent } from '../shared/modals/delivery-man/delivery-man.component';

import { AuthentificationComponent } from './authentification/authentification.component';
import { SigninComponent } from './signin/signin.component';
import { RegisterComponent } from './register/register.component';

import { OffresListComponent } from './offres-list/offres-list.component';
import { MenuBoxComponent } from './menu-box/menu-box.component';
import { ManagerProductListComponent } from './ManagerProductList/ManagerProductList.component';
import { ProductViewComponent } from '../shared/modals/product-view/product-view.component';
import { OrderBoxComponent } from './order-box/order-box.component';
import { ManagerOrdersComponent } from './manager-orders/manager-orders.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';


import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';


import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

import { TemplateOneFooterComponent } from './template-one-layouts/template-one-footer/template-one-footer.component';
import { TemplateOneNavbarComponent } from './template-one-layouts/template-one-navbar/template-one-navbar.component';
import { SlidersComponent } from './sliders/sliders.component';
import { ContactPageInfoComponent } from './contact-page-info/contact-page-info.component';
import { ViewMapComponent } from './view-map/view-map.component';
import { TemplateOneMenuBoxComponent } from './template-one-layouts/template-one-menu-box/template-one-menu-box.component';
import { TemplateOnePlatBoxComponent } from './template-one-layouts/template-one-plat-box/template-one-plat-box.component';
import { TemplateTwoNavbarComponent } from './template-two-layouts/template-two-navbar/template-two-navbar.component';
import { ReservationBoxComponent } from './reservation-box/reservation-box.component';
import { NavbarManagerComponent } from './navbar-manager/navbar-manager.component';
import { DeliveryManOrdersComponent } from './delivery-man-orders/delivery-man-orders.component';
import { AddressInputComponent } from './address-input/address-input.component';


import { RecaptchaModule } from "ng-recaptcha";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AddressModalComponent } from './address-modal/address-modal.component';

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    NavbarComponent,
    FavoritComponent,
    FooterComponent,
    ProductBoxComponent,
    ReservationBlocComponent,
    RestaurantBoxComponent,
    FilterComponent,

    AuthentificationComponent,
    SigninComponent,
    RegisterComponent,
    ResetPasswordComponent,
 

    RestaurantRightsidebarComponent,
    AddToCartButtonComponent,
    ProductListComponent,
    ProductCartComponent,
    OffresListComponent,
    MenuBoxComponent,
    ManagerProductListComponent,
    OrderBoxComponent,
    ManagerOrdersComponent,
    TemplateOneFooterComponent,
    TemplateOneNavbarComponent,
    SlidersComponent,
    ContactPageInfoComponent,
    ViewMapComponent,
    TemplateOneMenuBoxComponent,
    TemplateOnePlatBoxComponent,
    TemplateTwoNavbarComponent,
    ReservationBoxComponent,
    NavbarManagerComponent,
    DeliveryManOrdersComponent,
    AddressInputComponent,
    AddressModalComponent,
    

  ],
  imports: [
    CommonModule,
    MatFormFieldModule,

    MatSelectModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatTooltipModule,

    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    RouterModule,
    AgmDirectionModule,

    RecaptchaModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCTwmQNMKpJt6fUEN8pxvHaQlDqGfI3uV8',
      libraries: ['places']
    }),
    SlickCarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    NavbarComponent,
    NavbarManagerComponent,
    RestaurantRightsidebarComponent,
    FavoritComponent,
    FooterComponent,
    ProductBoxComponent,
    ReservationBlocComponent,
    RestaurantBoxComponent,
    AddToCartButtonComponent,
    ProductListComponent,
    ProductCartComponent,
    FilterComponent,
    OffresListComponent,
    OrderBoxComponent,
    MenuBoxComponent,
    ManagerOrdersComponent,
    DeliveryManOrdersComponent,
    TemplateOneFooterComponent,
    TemplateOneNavbarComponent,
    TemplateTwoNavbarComponent,
    SlidersComponent,
    ViewMapComponent,
    TemplateOneMenuBoxComponent,
    ContactPageInfoComponent,
    SigninComponent,
    RegisterComponent,
    ResetPasswordComponent,
    AuthentificationComponent,
    ReservationBoxComponent,
    AddressInputComponent,
    AddressModalComponent
  ],
  entryComponents: [
    ProductViewComponent,
    ValidateReservationComponent,
    DeliveryManComponent,
    AuthentificationComponent,
    SigninComponent,
    RegisterComponent,
    ResetPasswordComponent,
    AddressModalComponent,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  }],
})
export class LayoutsModule { }
