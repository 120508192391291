import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { SigninComponent } from 'src/app/layouts/signin/signin.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  message = '';
  role = '';
  captchaResponse = '';
  signIn: NgbModalRef;
  hideConf = true;
  hide = true;

  captchaKey = environment.recaptchaSiteKey;

  constructor(
    private authServ: AuthService,
    private localStorageServ: LocalStorageService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
      birthday: new FormControl(''),
      gender: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      countryCode: new FormControl('', [Validators.required]),
      country: new FormControl('',),
      fullAdress: new FormControl('', [Validators.required]),
      roles: new FormControl('')
    })
    
  }

  clicked(event){
    if(event.target.checked){
      this.role = 'DELIVERYMAN';
    }else {
      this.role = '';
    }
  }

  createAccount() {
    // --- create Full adress
    this.registerForm.value.fullAdress = this.registerForm.value.street + this.registerForm.value.postalCode + this.registerForm.value.city + this.registerForm.value.country;
    if(this.role !== ''){
      this.registerForm.value.roles = 'DELIVERYMAN';
    } else {
      this.registerForm.value.roles = '';
    }
    
    this.authServ.register(this.registerForm.value).then(
      resp=> {
          let response: any = resp

          if (response && response.status === true ){
            let user = response.data
            this.localStorageServ.setItem('user_email', user.email);
            this.localStorageServ.setItem('user_role', user.roles);
              setTimeout(() => {
                window.location.reload();
            }, 1000);
          } else {
              this.message = response.error?.error.message
              setTimeout(() => {
                this.message = ''
             }, 5000);
          }
      },
      error => {

      }
    );
  }

  close() {
    this.modalService.dismissAll();
  }



  openSignIn(){
    this.signIn = this.modalService.open(
      SigninComponent,
      { backdrop: 'static', size: 'm', keyboard: false, centered: true }
    );
  }


  resolved(captchaResponse: string) {
    
    this.captchaResponse = captchaResponse;
  }

}
