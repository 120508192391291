import { Component, ElementRef, OnInit, NgZone, Inject } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LocalStorageService } from './services/localstorage/local-storage.service';
import { filter } from 'rxjs/operators';
import './../assets/smtp.js';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { DatePipe, DOCUMENT } from '@angular/common';

import { WhiteMarkService } from './services/white-mark.service';
import { TunitableService } from 'src/app/services/tunitable.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './services/theme.service';
import { Observable } from 'rxjs';
import { AddressModalComponent } from './layouts/address-modal/address-modal.component';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        DatePipe,
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    theme$: Observable<boolean>;


    latitude: number;
    longitude: number;
    zoom: number;
    address: string;
    private geoCoder;
    config: any;
    currentPath: any;
    whiteLabelConfig: any;

    addressModal: NgbModalRef;


    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private mapsAPILoader: MapsAPILoader,
        private themeService: ThemeService,
        public datepipe: DatePipe,
        private localStorageServ: LocalStorageService,
        private tunitableServ: TunitableService,
        private whiteLabelServ: WhiteMarkService,
        public translate: TranslateService,
        private modalService: NgbModal
    ) {
        let lang = 'fr'
        translate.addLangs(['en', 'fr']);
        if (this.localStorageServ.getItem('lang')) {
            translate.setDefaultLang(this.localStorageServ.getItem('lang'));
            lang = this.localStorageServ.getItem('lang')
        } else {
            if (navigator.language.includes('en')) {
                translate.setDefaultLang('en')
                lang = 'en'
            } else {
                translate.setDefaultLang('fr')
            }
        }
        this.localStorageServ.setItem('lang', lang);
    }

    ngOnInit() {
        this.recallJsFuntions();
        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder;
        });

        this.getUserConfig();
        this.whiteLabelConfig = JSON.parse(this.localStorageServ.getItem('whiteMark')) || [];
        this.getWhiteLabelConfig();
    }


    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('#preloaders').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                // $.getScript('assets/js/quickmunch.js');
                $('#preloader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }


    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 8;
                this.getAddress(this.latitude, this.longitude);
            });
        }
    }



    getAddress(latitude, longitude) {
        console.log("latitude ===> ", latitude);
        console.log("longitude ===> ", longitude);

        const currentLocation = this.localStorageServ.getItem('user_locality');
        console.log(this.config);

        // 48.88930123278578, 2.178490155954896
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const address: any = results[0];
                    address.address_components.forEach(element => {
                        if (element.types.includes("locality")) {
                            if (!this.config) {
                                this.tunitableServ.setConfig({ position: { latitude: latitude, longitude: longitude } }).then(
                                    (resp: any) => {
                                        if (resp.status) {
                                            console.log(resp);
                                            
                                            this.localStorageServ.setItem('user_locality', element.long_name);
                                            this.localStorageServ.setItem('user_config_id', resp.data._id);
                                        }


                                    }
                                )
                                // this.addressModal = this.modalService.open(AddressModalComponent, {
                                //     backdrop: 'static',
                                //     size: 'l',
                                //     keyboard: false,
                                //     centered: true,
                                //   });
                            }

                            else {

                                this.localStorageServ.setItem('user_config_id', this.config?._id);
                                // if (this.config && this.config.position?.latitude !== latitude && this.config.position?.longitude !== longitude && currentLocation !== element.long_name) {

                                //     this.tunitableServ.updateConfig(this.config._id, { position: { latitude: latitude, longitude: longitude } }).then(
                                //         (resp: any) => {
                                //             this.localStorageServ.setItem('user_locality', element.long_name);

                                //         }
                                //     )

                                // }

                            }

                        }
                    });
                }
            }
        });
    }


    getUserConfig() {
        this.tunitableServ.getConfig().then(
            (resp: any) => {

                if (resp.status) {
                    this.config = resp.data.userConfig;
                }
                //this.setCurrentLocation();
            },
            (error) => {
                console.log(error);
            }
        )
    }

    getWhiteLabelConfig() {

        this.whiteLabelServ.getConfig().then(
            (resp: any) => {
                if (resp.status) {

                    if (resp.data.whiteLabel) {
                        const date = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
                        let whiteLabel: any = resp.data.whiteLabel;
                        whiteLabel['creationDate'] = date;
                        this.localStorageServ.setItem('whiteMark', JSON.stringify(whiteLabel));
                        //this.changeCssFile(whiteLabel.template);
                        if (whiteLabel.extras && whiteLabel.extras.color1) {
                            document.documentElement.style.setProperty('--first-color', whiteLabel.extras.color1);
                        }
                        if (whiteLabel.extras && whiteLabel.extras.color2) {
                            document.documentElement.style.setProperty('--second-color', whiteLabel.extras.color2);
                        }

                        if (whiteLabel.extras && whiteLabel.extras.policeNameTitle !== 'null' && whiteLabel.extras.policeTitle !== 'null') {
                            document.documentElement.style.setProperty('--font-family-title', whiteLabel.extras.policeNameTitle);
                            this.changeFontLink(whiteLabel.extras.policeTitle)
                        }

                        if (whiteLabel.extras && whiteLabel.extras.policeNameText !== 'null' && whiteLabel.extras.policeText !== 'null') {
                            document.documentElement.style.setProperty('--font-family-text', whiteLabel.extras.policeNameText);
                            this.changeFontLink(whiteLabel.extras.policeText)
                        }

                        this.router.navigate(['v1'])
                    } else {
                        this.localStorageServ.setItem('whiteMark', null);
                        // this.router.navigate(['/'])
                        //this.changeCssFile()
                    }
                }
            },
            (error: any) => {
                console.log(error);

            }
        )
    }

    // changeCssFile(template?) {

    //     if (template === "1") {
    //         this.themeService.setDarkMode(true);
    //     } else {
    //          this.themeService.setMainTheme("theme-default");
    //     }
    // }

    /**
 * changes the style according to the choosen language
 * @param lang : language choosen by user
 */
    changeFontLink(link: string) {
        let headTag = this.document.getElementsByTagName('head')[0] as HTMLHeadElement;

        let newLink = this.document.createElement('link');
        newLink.href = link;
        newLink.rel = 'stylesheet';
        headTag.appendChild(newLink);

    }

}


