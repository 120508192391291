import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  constructor(private http: HttpClient) { }

  addReservation(object) {
    return new Promise(resolve => {
      this.http.post(environment.Api_Url + 'reservations', object).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getReservationByRestaurant(restaurantId) {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'reservations/by-resto/' + restaurantId).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getReservationByUSer() {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'reservations').subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getManagerReservation(id,page,limit) {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'reservations/manager/' + id+'/'+page + '/'+ limit).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getAllReservations() {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'reservations/get-all' ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  updateReservation(reservationId, object){
    return new Promise(resolve => {
      this.http.patch(environment.Api_Url + 'reservations/' + reservationId, object).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getOne(id) {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'reservations/' + id).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }
}
