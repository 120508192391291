<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">

  <div *ngIf="type === 'signin'">
    <app-signin></app-signin>
    <hr>
    <div class="form-group mt-2 mb-1 text-center">
      <p class="text-light-black mt-0 mb-1">
        <a class="btn-second btn-submit full-width" href="javascript:void(0)" (click)="type = 'register'">
          {{'HOME.joinUs'|translate }} </a>
      </p>

      <p class="text-light-black mt-0 mb-1">
        <a href="javascript:void(0)" (click)="type = 'resetPassword'">{{'CLIENT.resetpassword'|translate }}</a>
      </p>

    </div>
  </div>
  <div *ngIf="type === 'register'">
    <app-register></app-register>
    <hr>
    <div class="form-group mt-2 mb-1 text-center">
      <p class="text-light-black mt-0 mb-1">
        {{'CLIENT.alreadyClient'|translate }} ?
        <a href="javascript:void(0)" (click)="type = 'signin'">{{'HOME.connect'|translate }}</a>
      </p>
      <p class="text-light-black mt-0 mb-1">
        <a href="javascript:void(0)" (click)="type = 'resetPassword'">{{'CLIENT.resetpassword'|translate }}</a>
      </p>
    </div>
  </div>


  <div *ngIf="type === 'resetPassword'">
    <app-reset-password></app-reset-password>

    <hr>
    <div class="form-group mt-2  mb-1 text-center">
      <p class="text-light-black mt-0 mb-1">
        <a class="btn-second btn-submit full-width" href="javascript:void(0)" (click)="type = 'register'">
          {{'HOME.joinUs'|translate }}
        </a>
      </p>
      <p class="text-light-black mt-0 mb-1">
        {{'CLIENT.alreadyClient'|translate }} ?
        <a href="javascript:void(0)" (click)="type = 'signin'">{{'HOME.connect'|translate }}</a>
      </p>
    </div>
  </div>





  <hr>
  <p class="text-light-black m-0 text-center p-0 partner-link">
    <a routerLink="/become-partner">{{ 'HOME.BecomePartner' | translate }}</a>
  </p>


</div>
