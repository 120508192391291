import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './cart.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
   providedIn: 'root'
})
export class TunitableService {

   currency = '€';
   deliveryFee = 3.5;
   navbarCartCount = 0;
   CartProducts: any;
   Cart: any;


   constructor(
      private toastr: ToastrService,
      private http: HttpClient,
      private LocalStorageServ: LocalStorageService,
      private CartServ: CartService,
      public translate: TranslateService,

   ) {

   }




   // Adding new Product to cart in localStorage
   public addToCart(data: any, restaurant) {

      const products: any = {
         restaurant,
         item: data.value?._id,
         type: data.type,
         quantity: 1
      };

      this.toastr.show(this.translate.instant('TOASTR.addtoCart'), '', {
         timeOut: 500,
      });

      this.addToCartProd(products);


      // if (!products || !products.restaurant || products.restaurant === restaurant) {

      //    const categoryProduct = data.type;


      //    let index = -1;

      //    index = products[categoryProduct].findIndex(
      //          element => element._id === data.value._id
      //    );

      //    if (index === -1 ) {
      //       const productToCart = {
      //          _id: data.value._id,
      //          name: data.value.name,
      //          description: data.value.description,
      //          price: data.value.price,
      //          picture: data.value.picture || [],
      //          quantity: 1
      //       };
      //       products.restaurant = restaurant;
      //       products[categoryProduct].push(productToCart);
      //    }

      //    if (index > -1) {
      //       this.toastr.warning('Product already in cart', '', {
      //          timeOut: 1500,
      //       });
      //    }

      //    setTimeout(() => {
      //       if (index === -1) {
      //          this.toastr.success('Product added to cart', '', {
      //             timeOut: 1500,
      //          });
      //          this.LocalStorageServ.setItem('cart', JSON.stringify(products));
      //       }

      //    }, 1000);

      // }


   }

   // returning LocalCarts Product Count
   public calculateLocalCartProd() {
      this.CartProducts = [];
      this.CartServ.getCart().then(
         (resp: any) => {
            if (resp.status) {
               this.CartProducts = resp.data.cart || [];
            }
         },
         (error: any) => {
            console.log(error);
         }
      );
   }


   public addToCartProd(product) {
      this.Cart = [];
      this.CartServ.getCart().then(
         (resp: any) => {
            if (resp.status) {
               
               this.Cart = resp.data.cart ? resp.data.cart[0] : [];
               if (!this.Cart || !this.Cart.restaurant || this.Cart.restaurant._id === product.restaurant) {

                  this.CartServ.addToCart(product).then(
                     (response: any) => {
                        if (response.status) {
                           this.toastr.success(this.translate.instant('TOASTR.productAddedToCart'), '', {
                              timeOut: 1500,
                           });
                           this.LocalStorageServ.setItem('cartChanged', true);
                        }
                     },
                     (err: any) => {
                        console.log(err);
                     }
                  );

               }
            } else {
            }
         },
         (error: any) => {
            console.log(error);
         }
      );
   }


   public calculateLocalCartProdCounts() {
      return this.navbarCartCount;
   }


   // Removing cart from local
   public removeLocalCartProduct(product: any, categoryProduct) {
      const products: any = JSON.parse(this.LocalStorageServ.getItem('cart'));

      this.LocalStorageServ.removeItem('cart');

      for (let i = 0; i < products[categoryProduct].length; i++) {
         if (products[categoryProduct][i]._id === product._id) {
            products[categoryProduct].splice(i, 1);
            break;
         }
      }

      this.toastr.show(this.translate.instant('TOASTR.deleteProduct'), '', {
         timeOut: 500,
      });

      setTimeout(() => {

         this.toastr.success(this.translate.instant('TOASTR.productDeleted'), '', {
            timeOut: 1500,
         });
         this.LocalStorageServ.setItem('cart', JSON.stringify(products));
         this.calculateLocalCartProdCounts();
      }, 500);
   }


   removerProduct(id, item) {

      this.toastr.show(this.translate.instant('TOASTR.deleteProduct'), '', {
         timeOut: 500,
      });

      const data = {
         id,
         item
      };

      this.CartServ.removeItem(data).then(
         (resp: any) => {
            if (resp.status) {
               this.toastr.success(this.translate.instant('TOASTR.productDeleted'), '', {
                  timeOut: 1500,
               });
            }
            this.LocalStorageServ.setItem('cartChanged', true);
         },
         error => {
            console.log(error);
         }
      );
   }


   setConfig(data){
      return new Promise((resolve) => {
         this.http.post(environment.Api_Url + 'configs', data).subscribe(
           (res: any) => {
             resolve({ status: true, data: res });
           },
           (err) => {
             resolve({ status: false, error: err });
           }
         );
       });
   }

   getConfig(){
      return new Promise((resolve) => {
         this.http.get(environment.Api_Url + 'configs').subscribe(
           (res: any) => {
             resolve({ status: true, data: res });
           },
           (err) => {
             resolve({ status: false, error: err });
           }
         );
       });
   }

   updateConfig(id,data){

      return new Promise((resolve) => {
         this.http.patch(environment.Api_Url + 'configs/'+id, data).subscribe(
           (res: any) => {
             resolve({ status: true, data: res });
           },
           (err) => {
             resolve({ status: false, error: err });
           }
         );
       });
   }
}
