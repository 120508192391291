import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnInit {


  address = '';
  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }


  changePlace(event) {
    console.log(event);

  }


  close() {
    this.modalService.dismissAll();
  }
}
