import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.scss']
})
export class AuthentificationComponent implements OnInit {


  register = false;
  type = "signin";
  
  constructor(
    private modalService: NgbModal,
  ) { }


  ngOnInit(): void {
  }



  close() {
    this.modalService.dismissAll();
  }
}
