import { Component,  OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {


  @Output() PasswordChanged: EventEmitter<any> = new EventEmitter();

  resetForm: FormGroup;
  newPasswordForm: FormGroup;
  message = '';
  role = '';
  captchaResponse = '';
  hideConf = true;
  hide = true;
  success = false;
  proceed = false;
  captchaKey = environment.recaptchaSiteKey;
  constructor(
    private authServ: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {


    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required])
    })

    this.newPasswordForm = new FormGroup({
      token: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    })

  }


  resolved(captchaResponse: string) {

    this.captchaResponse = captchaResponse;
  }


  SendEmail() {
    if (this.resetForm.valid) {
      this.authServ.sendRestEmail(this.resetForm.value).then(
        (resp: any) => {
          if (resp.status) {
            this.message = 'successSendEmail'
            this.success = true
            this.captchaResponse = '';
            setTimeout(() => {
              this.proceed = true
            }, 3000);

          } else {
            this.message = 'errorSendEmail'
            this.success = false
          }
    
        }
      )
    }

    setTimeout(() => {
      this.message = ''
    }, 3000);
  }


  resetPassword() {
    if (this.newPasswordForm.valid) {

      this.authServ.resetPassword(this.newPasswordForm.value).then(
        (resp: any) => {
          if (resp.status) {
            this.message = 'successResetPassword';
            this.success = true;

            setTimeout(() => {
              this.modalService.dismissAll();
            }, 3000);


          } else {
            this.message = 'errorResetPassword';
            this.success = false
          }

        }
      )

      setTimeout(() => {
        this.message = ''
      }, 3000);

    }
  }
}
