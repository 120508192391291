import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ReservationsService } from 'src/app/services/reservations/reservations.service';
import { RestaurantsService } from 'src/app/services/restaurants.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-validate-reservation',
  templateUrl: './validate-reservation.component.html',
  styleUrls: ['./validate-reservation.component.scss'],
  providers: [DatePipe]
})
export class ValidateReservationComponent implements OnInit {
  reservationForm: FormGroup;
  userForm: FormGroup;
  restaurantForm: FormGroup;
  guestReservationForm: FormGroup;
  // availableDate;

  selectedCap
  finalselected
  selectedDate
  minDate: NgbDateStruct;
  url = environment.Api_Url;
  currentDate: any;
  availableDate: any;

  reservationType: any;
  submitted_form = false;

  @Input() restaurant;
  @Input() capacity;
  @Input() restaurantPromo;
  @Input() reservation: FormGroup;


  slideConfig = {
    infinite: false,
    slidesToShow: 8,
    slidesToScroll: 6,
    autoplay: false,
    autoplaySpeed: 2500,
    arrows: true,
    dots: false,
    nextArrow: '<button class="btn btn-round next"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><defs><style>.a,.b,.d{fill:none;}.a,.b{stroke:#fff;stroke-width:1.5px;}.a{stroke-linecap:round;stroke-linejoin:round;}.c{stroke:none;}</style></defs><g transform="translate(-526 502) rotate(-90)"><path class="a" d="M9,13.5l5,5,5-5" transform="translate(475 522.5)"/><g class="b" transform="translate(502 526) rotate(90)"><circle class="c" cx="12.5" cy="12.5" r="12.5"/><circle class="d" cx="12.5" cy="12.5" r="11.75"/></g></g></svg></button>',
    prevArrow: '<button class="btn btn-round prev"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><defs><style>.a{opacity:0.5;}.b,.c,.e{fill:none;}.b,.c{stroke:#fff;stroke-width:1.5px;}.b{stroke-linecap:round;stroke-linejoin:round;}.d{stroke:none;}</style></defs><g class="a" transform="translate(0 25) rotate(-90)"><path class="b" d="M0,5,5,0l5,5" transform="translate(7 10)"/><g class="c" transform="translate(0 25) rotate(-90)"><circle class="d" cx="12.5" cy="12.5" r="12.5"/><circle class="e" cx="12.5" cy="12.5" r="11.75"/></g></g></svg></button>',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          slidesToShow: 5,
          slidesToScroll: 4,
          autoplay: true,
          autoplaySpeed: 2000
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 5,
          slidesToScroll: 4,
          autoplay: true,
          autoplaySpeed: 2000
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 5,
          slidesToScroll: 4,
          autoplay: true,
          autoplaySpeed: 2000
        }
      }
    ]
  };


  constructor(
    private modalService: NgbModal,
    private reservationServ: ReservationsService,
    public authServ: AuthService,
    private restaurantServ: RestaurantsService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public translate: TranslateService,
    private localStorageServ: LocalStorageService,

  ) {

    translate.addLangs(['en', 'fr']);

    if (this.localStorageServ.getItem('lang')) {
      translate.setDefaultLang(this.localStorageServ.getItem('lang'));
    } else {
      navigator.language.includes('en')
        ? translate.setDefaultLang('en')
        : translate.setDefaultLang('fr');
    }
    
    console.log('herrre ===>');
    
    registerLocaleData(localeFr, 'fr');
    


  }



  ngOnInit(): void {


    this.localStorageServ.watchStorage().subscribe((data: string) => {
      if (this.localStorageServ.getItem('lang')) {
        this.translate.setDefaultLang(this.localStorageServ.getItem('lang'));
      } else {
        this.translate.setDefaultLang('en');
      }
    });

    this.reservationForm = new FormGroup({
      restaurant: new FormControl(this.restaurant._id),
      noteCustomer: new FormControl('', Validators.required),
      nbrePerson: new FormControl(),
      availableDate: new FormControl(),
      type: new FormControl(),
    })

    this.userForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required)
    })

    this.restaurantForm = new FormGroup({
      restaurantName: new FormControl(''),
      restaurantAddress: new FormControl(''),
      restaurantEmail: new FormControl('')
    })


    this.guestReservationForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      restaurant: new FormControl(this.restaurant._id),
      noteCustomer: new FormControl('', Validators.required),
      nbrePerson: new FormControl(1),
      availableDate: new FormControl(''),
      type: new FormControl(),

    })

    let today = new Date();
    this.currentDate = this.formateDate(today);
    this.minDate = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };


  }




  getFormsInformations() {
    // -- user information
    if (this.authServ.isSignedIn()) {
      this.authServ.getConnectedUser().then((res: any) => {
        if (res.status) {
          this.userForm.controls['firstName'].setValue(res['data']['user'].profile.firstName);
          this.userForm.controls['email'].setValue(res['data']['user'].email);
        } else {
          console.log(res.error);
        }
      });
    }

    // this.restaurantServ.getOneRestaurant(this.restaurant._id).then((res: any) => {
    //   if (res.status) {
    //     this.restaurantForm.controls['restaurantName'].setValue(res['data']['restaurant'].name);
    //     this.restaurantForm.controls['restaurantAddress'].setValue(res['data']['restaurant'].address.fullAddress);
    //     this.restaurantForm.controls['restaurantEmail'].setValue(res['data']['restaurant'].email);
    //   }
    // })






  }

  createReservation() {

    this.submitted_form = true
    //this.getFormsInformations();
    if (this.authServ.isSignedIn()) {

      this.reservationForm.controls['availableDate'].setValue(this.finalselected);
      this.reservationForm.controls['type'].setValue(this.reservationType);
      this.reservationForm.controls['nbrePerson'].setValue(this.selectedCap);

      this.reservationServ.addReservation(this.reservationForm.value).then((res: any) => {
        if (res.status) {
          this.modalService.dismissAll();
          this.toastr.success(this.translate.instant('TOASTR.acceptReservation'), this.translate.instant('TOASTR.congrats'), {
            timeOut: 1500
          });
        } else {
          this.toastr.error(this.translate.instant('TOASTR.tryAgain'), this.translate.instant('TOASTR.error'), {
            timeOut: 1500
          });
        }
      })
    } else {

      this.guestReservationForm.controls['type'].setValue(this.reservationType);
      this.guestReservationForm.controls['noteCustomer'].setValue(this.reservationForm.value.noteCustomer);
      this.guestReservationForm.controls['availableDate'].setValue(this.finalselected);
      this.guestReservationForm.controls['nbrePerson'].setValue(this.selectedCap);

      this.reservationServ.addReservation(this.guestReservationForm.value).then((res: any) => {
        if (res.status) {
          this.modalService.dismissAll();
          this.toastr.success(this.translate.instant('TOASTR.acceptReservation'), this.translate.instant('TOASTR.Success'), {
            timeOut: 1500
          });
        } else {
          this.toastr.error(this.translate.instant('TOASTR.tryAgain'), this.translate.instant('TOASTR.error'), {
            timeOut: 1500
          });
        }
      })
    }

    //  this.close();

  }

  close() {
    this.modalService.dismissAll();
  }


  setCapacity(cap) {
    this.selectedCap = cap;
    this.reservationForm.controls['nbrePerson'].setValue(cap);
  }

  setDate() {

    const selected = new Date(this.availableDate?.year + '-' + this.availableDate?.month + '-' + this.availableDate?.day);
    this.selectedDate = this.formateDate(selected);

    this.finalselected = this.selectedDate;
  }


  getDate(date, days) {
    let futureDate = new Date(date);
    futureDate.setDate(futureDate.getDate() + days);

    let futureDateString = this.formateDate(futureDate);
    return futureDateString;

  }


  setFinalSelect(days) {
    let newDate: any
    if (!this.selectedDate) {
      newDate = new Date(this.currentDate);
      newDate.setDate(newDate.getDate() + days)
      this.finalselected = this.formateDate(newDate);
    } else {
      newDate = new Date(this.selectedDate);
      newDate.setDate(newDate.getDate() + days)
      this.finalselected = this.formateDate(newDate);
    }

    console.log(this.finalselected);
    

  }


  formateDate(date) {

    const d = new Date(date);
    const datestring = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
   
    return datestring;
  }

  getReservationFormControl(control) {
    return this.reservationForm.get(control);
  }

  getGuestFormControl(control) {
    return this.guestReservationForm.get(control);
  }

}
