// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


const baseOrigine = 'https://api.clicknmeal.fr/';

export const environment = {
  Api_Url: baseOrigine,
  restaurant_media: baseOrigine+'media/restaurants/',
  menus_media: baseOrigine+'media/menus/',
  paltes_media: baseOrigine+'media/plates/',
  cuisines_media: baseOrigine+'media/cuisines/',
  recaptchaSiteKey:"6LemMu4aAAAAACu_y2JDhXbjrZW5BWMKLFiFp86K",
  canOrder:false,
  production: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
