<div class="reservation" #mymodal>


  <div class="modal-header" *ngIf="restaurant && restaurant.cover"
    [style.backgroundImage]="'url('+ url + restaurant.cover.path+')'">
    <p class="text-light-white header-title title m-0"> {{ 'RESERVATION.bookAt' | translate }} {{restaurant.name}} </p>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-header" *ngIf="restaurant && !restaurant.cover"
    [style.backgroundImage]="'url(assets/img/restaurant/la-carte.jpg)'">
    <p class="text-light-white header-title title m-0"> {{ 'RESERVATION.bookAt' | translate }} {{restaurant.name}} </p>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body form-custom">
    <!-- about client -->

    <div class="reservation-form-content">



      <div class="row" *ngIf="capacity && capacity.length > 0">
        <div class="col-md-12">
          <label class="text-grey-white fs-14">{{ 'FORMS_RESERVATION.nbrPerson' | translate }} : </label>
          <div class="persons-number">
            <ngx-slick-carousel class="carousel home-3-slider" [config]="slideConfig">
              <div ngxSlickItem *ngFor="let cap of capacity| slice:1:capacity.length">
                <span class="item" [class.active]="selectedCap == cap" (click)="setCapacity(cap)">{{ cap }}

                </span>
                <!-- <span class="reduction">
                                 <span class="promotion-value"  *ngIf="restaurantPromo && restaurantPromo.isPercent">
                                    {{restaurantPromo.reduction}} %
                                  </span>
                          
                                  <span class="promotion-value"   *ngIf="restaurantPromo &&  restaurantPromo.isPercent === false">
                                    {{restaurantPromo.reduction}} €
                                  </span>
                            </span> -->
              </div>
            </ngx-slick-carousel>

          </div>
        </div>
      </div>



      <!-- about reservation -->

      <div class="row">
        <div class="col-md-8">
          <label class="text-grey-white fs-14">Date : </label>
        </div>
        <div class="col-md-4 text-right">
          <div data-testid="calendar">
            <button #buttonEl class="btn calendar" (click)="d.toggle()" type="button">
              <label class="text-grey-white fs-14">{{ 'FORMS_RESERVATION.moreDates' | translate }} : </label>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-calendar">
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                <line x1="16" y1="2" x2="16" y2="6" />
                <line x1="8" y1="2" x2="8" y2="6" />
                <line x1="3" y1="10" x2="21" y2="10" /></svg>
            </button>
            <input (dateSelect)="setDate()" class="form-control" hidden placeholder="yyyy-mm-dd" name="dp"
              [(ngModel)]="availableDate" [minDate]="minDate" ngbDatepicker #d="ngbDatepicker" [placement]="'bottom'"
              [positionTarget]="buttonEl">
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-4">
          <span *ngIf="!selectedDate || selectedDate === currentDate" [class.active]="finalselected === currentDate"
            (click)="setFinalSelect(0)" class="dateDay"> {{ 'FORMS_RESERVATION.today' | translate }} </span>
          <span *ngIf="selectedDate && selectedDate !== currentDate" [class.active]="finalselected === selectedDate"
            (click)="setFinalSelect(0)" class="dateDay"> {{getDate(selectedDate,0) | date:'short':'':'fr'}} </span>

        </div>
        <div class="col-md-4">
          <span *ngIf="!selectedDate" [class.active]="finalselected === getDate(currentDate,1)"
            (click)="setFinalSelect(1)" class="dateDay"> {{ 'FORMS_RESERVATION.tomorrow' | translate }} </span>
          <span *ngIf="selectedDate" [class.active]="finalselected === getDate(selectedDate,1)"
            (click)="setFinalSelect(1)" class="dateDay"> {{getDate(selectedDate,1) | date:'short':'':'fr'}} </span>


        </div>
        <div class="col-md-4">
          <span *ngIf="!selectedDate" [class.active]="finalselected === getDate(currentDate,2)"
            (click)="setFinalSelect(2)" class="dateDay"> {{getDate(currentDate,2) | date:'short':'':'fr'}} </span>
          <span *ngIf="selectedDate" [class.active]="finalselected === getDate(selectedDate,2)"
            (click)="setFinalSelect(2)" class="dateDay"> {{getDate(selectedDate,2) | date:'short':'':'fr'}} </span>

        </div>
      </div>

      <!-- <div class="form-group">
                <label class="text-grey-white fs-14" >{{ 'FORMS_RESERVATION.nbrPerson' | translate }} :</label>
                <select aria-label="Party size selector" class="form-control" data-test="party-size-picker" formControlName="nbrePerson">
                    <option *ngFor=" let cap of capacity" [ngValue]="cap">{{ cap }}</option>
                </select>            
            </div> -->



      <form [formGroup]="guestReservationForm" *ngIf="finalselected && !authServ.isSignedIn()  ">
        <div class="row">

          <div class="col-md-6">
            <div class="form-group">
              <label class="text-grey-white fs-14">{{ 'CLIENT.lastName' | translate }} :</label>
              <input type="text" class="form-control" placeholder="{{ 'CLIENT.lastName' | translate }}"
                formControlName="lastName">

              <div *ngIf="submitted_form && getGuestFormControl('lastName').errors?.required" class="alert-danger">
                <div *ngIf="getGuestFormControl('lastName').errors.required">{{ 'CLIENT.lastName' | translate }}
                  {{ 'CLIENT.required' | translate }}.</div>
              </div>

            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="text-grey-white fs-14">{{ 'CLIENT.firstName' | translate }} :</label>
              <input type="text" class="form-control" placeholder="{{ 'CLIENT.firstName' | translate }}"
                formControlName="firstName">

              <div *ngIf="submitted_form && getGuestFormControl('firstName').errors?.required" class="alert-danger">
                <div *ngIf="getGuestFormControl('firstName').errors.required">{{ 'CLIENT.firstName' | translate }}
                  {{ 'CLIENT.required' | translate }}.</div>
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="text-grey-white fs-14">{{ 'CLIENT.email' | translate }} :</label>
              <input type="text" class="form-control" placeholder="{{ 'CLIENT.email' | translate }}"
                formControlName="email">

              <div *ngIf="submitted_form && getGuestFormControl('email').errors?.required" class="alert-danger">
                <div *ngIf="getGuestFormControl('email').errors.required">{{ 'CLIENT.email' | translate }}
                  {{ 'CLIENT.required' | translate }}.</div>
              </div>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="text-grey-white fs-14">{{ 'CLIENT.phone' | translate }} :</label>
              <input type="text" class="form-control"
                placeholder="{{ 'FORMS_RESERVATION.phonePlaceholder' | translate }}" formControlName="phone">

              <div *ngIf="submitted_form && getGuestFormControl('phone').errors?.required" class="alert-danger">
                <div *ngIf="getGuestFormControl('phone').errors.required">{{ 'CLIENT.phone' | translate }}
                  {{ 'CLIENT.required' | translate }}.</div>
              </div>


            </div>
          </div>
        </div>
      </form>


      <div class="final-recept" *ngIf="finalselected && selectedCap">

        <div class="content">


          <div class="options">

            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="reservationType">
              <mat-radio-button *ngIf="restaurantPromo" value="1">

                <span *ngIf="restaurantPromo.isPercent"> <strong> - {{restaurantPromo.reduction}} % sur la carte
                  </strong> </span><br>
                <span *ngIf="!restaurantPromo.isPercent"> <strong> - {{restaurantPromo.reduction}} € sur la carte
                  </strong> </span>
                <span>Hors menu, hors boisson. Valable pour le créneau horaire réservé.</span>

              </mat-radio-button>

              <mat-radio-button value="0">
                <span> <strong>Réservation sans offre</strong> </span> <br>
                <span>La réservation « à la carte » standard sans promotion </span>
              </mat-radio-button>
            </mat-radio-group>

          </div>

          <form [formGroup]="reservationForm">
            <div class="form-group">
              <label class="text-grey-white fs-14">{{ 'FORMS_RESERVATION.Note' | translate }} :</label>
              <textarea type="text" class="form-control"
                placeholder="{{ 'FORMS_RESERVATION.notePlaceholder' | translate }}"
                formControlName="noteCustomer"></textarea>

              <div *ngIf="submitted_form && getReservationFormControl('noteCustomer').errors?.required"
                class="alert-danger">
                <div *ngIf="getReservationFormControl('noteCustomer').errors.required">
                  {{ 'FORMS_RESERVATION.Note' | translate }} {{ 'CLIENT.required' | translate }}.</div>
              </div>

            </div>
          </form>
        </div>
      </div>


    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="close()">{{ 'BUTTONS.cancel' | translate }}</button>
    <button type="button"
      [disabled]="!finalselected && !selectedCap && !guestReservationForm.valid && !reservationForm.valid"
      class="btn-second btn-submit" (click)="createReservation()">
      {{ 'BUTTONS.validate' | translate }}</button>
  </div>
</div>
