import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WhiteMarkService {

  constructor(private http: HttpClient) { }


  getRequests() {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'requests/list').subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getAll() {

    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'white-label').subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  getConfig(){

    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'white-label/configs').subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }

  getOne(id) {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'white-label/'+id).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  createRequest(id) {

    return new Promise(resolve => {
      this.http.post(environment.Api_Url + 'requests',{restaurant:id}).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  updateWhiteMark(id,data) {

    return new Promise(resolve => {
      this.http.patch(environment.Api_Url + 'white-label/'+id,data).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  DeleteGallery(id, id_image) {

    return new Promise(resolve => {
      this.http.patch(environment.Api_Url + 'white-label/' + id + '?slidersRemove=' + id_image, {}).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  activateWhiteMark(id,data){

    return new Promise(resolve => {
      this.http.patch(environment.Api_Url + 'white-label/activate/' + id, data).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }


  deleteWhiteMark(id){

    return new Promise(resolve => {
      this.http.delete(environment.Api_Url + 'white-label/' + id).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }

}
