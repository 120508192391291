import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private http: HttpClient) {}



  getCart() {
    return new Promise((resolve) => {
      this.http.get(environment.Api_Url + 'carts/user').subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  addToCart(data) {
    return new Promise((resolve) => {
      this.http.post(environment.Api_Url + 'carts', data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }


  removeItem(data){

    return new Promise((resolve) => {
      this.http.post(environment.Api_Url + 'carts/remove', data).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

  deleteCart(id){
    return new Promise((resolve) => {
      this.http.delete(environment.Api_Url + 'carts/' + id).subscribe(
        (res: any) => {
          resolve({ status: true, data: res });
        },
        (err) => {
          resolve({ status: false, error: err });
        }
      );
    });
  }

}
