<div class="modal-header">
  <h4 class="modal-title"> {{ 'CLIENT.changerAddress' | translate }} </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
</div>


<div class="modal-body">

  <address-input [address]="address" (PlaceChanged)="changePlace($event)"></address-input>

</div>
