<div class="delivery" #mymodal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ 'DELIVERYMAN.confirmBecomeDelivman' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ 'DELIVERYMAN.areYouSure' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="close()">{{ 'MANAGER.restaurants' | translate }}{{ 'BUTTONS.cancel' | translate }}</button>
        <button type="button" class="btn btn-outline-dark" (click)="confirm()" >{{ 'MANAGER.restaurants' | translate }}{{ 'BUTTONS.confirm' | translate }}</button>
    </div>
</div>