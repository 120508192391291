import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  updateUser(object){
    return new Promise(resolve => {
      this.http.patch(environment.Api_Url + 'users/profile', object ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  getManagerCount(){

    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'configs/counts' ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getNotifications(){
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'notifications' ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }
}
