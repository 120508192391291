import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  updatedRequest: any;

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this.updatedRequest = request.clone({
      // headers: request.headers.set('Authorization', 'Bearer ' + userToken)
      // headers: request.headers.set('Access-Control-Allow-Origin', '*'),
     // headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded'),
      withCredentials: true,
    });

    return next.handle(this.updatedRequest);
  }
}
