import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrNotifierService } from 'src/app/core/toastr/toastr-notifier.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-delivery-man',
  templateUrl: './delivery-man.component.html',
  styleUrls: ['./delivery-man.component.scss']
})
export class DeliveryManComponent implements OnInit {

  constructor(private modalService: NgbModal, private userServ: UserService, private toastrServ: ToastrNotifierService) { }

  ngOnInit(): void {
  }

  confirm() {
    this.userServ.updateUser({ roles: 'DELIVERYMAN' }).then(res => {
      if(res['status']){
        this.close();
        this.toastrServ.success('you ve become a delivery man', 'Congrats!')
      }
    })
  }

  close() {
    this.modalService.dismissAll();
  }

}
