import { Component, NgZone, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { TunitableService } from 'src/app/services/tunitable.service';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';

@Component({
  selector: 'address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit {

  @Output() PlaceChanged: EventEmitter<any> = new EventEmitter();
  @Input() address: any = '';
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private localStorageServ: LocalStorageService,
    private tunitableServ: TunitableService,
  ) { }


  location: any = [];

  userConfig = '';

  ngOnInit(): void {




    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        <HTMLInputElement>document.getElementById('autocomplete-address'),
        {
          types: ['address'], //['regions']
          componentRestrictions: {
            country: ['fr', 'de', 'it', 'tn'],
          },
        }
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          if (
            autocomplete['gm_accessors_'] &&
            autocomplete['gm_accessors_']['place'] &&
            autocomplete['gm_accessors_']['place']['Xc'] &&
            autocomplete['gm_accessors_']['place']['Xc']['formattedPrediction']
          ) {
            this.address =
              autocomplete['gm_accessors_']['place']['Xc'][
              'formattedPrediction'
              ];
          }

          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place) {
            this.location['latitude'] = place.geometry.location.lat();
            this.location['longitude'] = place.geometry.location.lng();

            console.log(this.localStorageServ.getItem('user_config_id'));

            this.localStorageServ.getItem('user_config_id') ? this.userConfig = this.localStorageServ.getItem('user_config_id') : '';

            if (this.userConfig !== '') {
              this.tunitableServ.updateConfig(this.userConfig, { position: { latitude: this.location['latitude'], longitude: this.location['longitude'] } }).then(
                (resp: any) => {

                  console.log("updateConfig ====>", resp);
                  if (resp.status) {
                    this.localStorageServ.setItem('user_locality', place.formatted_address);
                    this.localStorageServ.setItem('user_config_id', resp.data.userConfig._id);
                  }
                }
              )
            } else {

              this.tunitableServ.setConfig({ position: { latitude: this.location['latitude'], longitude: this.location['longitude'] } }).then(
                (resp: any) => {

                  console.log("setConfig ====>", resp);

                  if (resp.status) {
                    this.localStorageServ.setItem('user_locality', place.formatted_address);
                    this.localStorageServ.setItem('user_config_id', resp.data._id);
                  }

                }
              )
            }


            let data = {
              address: place.formatted_address,
              location: this.location,
            }
            this.address = place.formatted_address;
            this.PlaceChanged.emit(data);

          }
        });
      });
    });

  }

}
