import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RestaurantsService {

  constructor(private http: HttpClient) { }

  getRestaurants(
    page,
    limit,
    cuisines,
    dressCodes,
    diets,
    vegans,
    features,
    delivery,
    clickCollect,
    reservation,
    lat,
    long,
    rayon,
    priceRange
  ) {
    let cuisine = '';
    let dressCode = '';
    let diet = '';
    let vegan = '';
    let feature = '';
    let latitude = '';
    let longitude = '';

    for (let i = 0; i < cuisines.length; i++) {
      if (i < cuisines.length && i > 0) {
        cuisine += ',' + cuisines[i]
      } else {
        cuisine += cuisines[i]
      }
    }

    for (let i = 0; i < features.length; i++) {
      if (i < features.length && i > 0) {
        feature += ',' + features[i]
      } else {
        feature += features[i]
      }
    }

    for (let i = 0; i < diets.length; i++) {
      if (i < diets.length && i > 0) {
        diet += ',' + diets[i]
      } else {
        diet += diets[i]
      }
    }

    for (let i = 0; i < vegans.length; i++) {
      if (i < vegans.length && i > 0) {
        vegan += ',' + vegans[i]
      } else {
        vegan += vegans[i]
      }
    }


    for (let i = 0; i < dressCodes.length; i++) {
      if (i < dressCodes.length && i > 0) {
        dressCode += ',' + dressCodes[i]
      } else {
        dressCode += dressCodes[i]
      }
    }

    if (lat === '' || long === '') {
      latitude = '';
      longitude = '';
      rayon = ''
    }


    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'restaurants/get-all/' + page + '/' + limit + '?cuisines=' + cuisine + '&dressCodes=' + dressCode + '&veganCategory=' + vegan + '&diets=' + diet + '&clickCollect=' + clickCollect + '&delivery=' + delivery + '&latitude=' + lat + '&longitude=' + long + '&rayon=' + rayon + '&features=' + feature + '&acceptRSVNS=' + reservation + '&averageCost=' + priceRange).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getAllRestaurantPagination(page, limit) {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'restaurants/get-all/' + page + '/' + limit).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getManagerRestaurants() {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'restaurants').subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getRestaurantTypes() {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'types').subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  deleteRestaurant(id) {
    return new Promise(resolve => {
      this.http.delete(environment.Api_Url + 'restaurants/' + id).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getOneRestaurant(id) {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'restaurants/' + id).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getOneRestaurantBySlug(slug) {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'restaurants/slug/' + slug).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  getAllRestaurantsFilter(filter, value, page, limit) {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'restaurants/get-all/' + page + '/' + limit + '?' + filter + '=' + value).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getRestaurantsList() {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'restaurants/get-all').subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getRestaurantsNumber() {
    this.http.get(environment.Api_Url + 'restaurants/get-all').subscribe((res: any) => {
      return res.count;
    })
  }

  addRestaurant(data) {
    return new Promise(resolve => {
      this.http.post(environment.Api_Url + 'restaurants', data).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  updateRestaurant(id, data) {
    return new Promise(resolve => {
      this.http.patch(environment.Api_Url + 'restaurants/' + id, data).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  DeleteGallery(id_rest, id_image) {

    return new Promise(resolve => {
      this.http.patch(environment.Api_Url + 'restaurants/' + id_rest + '?galleryRemove=' + id_image, {}).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  getTypes() {

    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'types').subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }

  sendEmail(data) {

    return new Promise(resolve => {
      this.http.post(environment.Api_Url + 'restaurants/send-email', data).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }


  getNearRestaurant(page, limit) {

    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'restaurants/near/' + page + '/' + limit).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }

  getAllFeatures() {
    return new Promise(resolve => {
      this.http.get(environment.Api_Url + 'features').subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }



  sendManagerRequest(data) {
    return new Promise(resolve => {
      this.http.post(environment.Api_Url + 'requests-owner', data).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  sendRecommendation(data) {

    return new Promise(resolve => {
      this.http.post(environment.Api_Url + 'recommendations', data).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }
}
