import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class WhiteMarkGuard implements CanActivate {
  public canActivateChildren: boolean = true;

  constructor(
    private router: Router,
    private locastorageServ: LocalStorageService,
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const params = JSON.parse(this.locastorageServ.getItem('whiteMark')) || '';

    if (!params) {

      return true
    }

    if (params) {
      this.router.navigate(['/v1']);
    }

    this.router.navigate(['/v1']);
    return false;
  }

}
